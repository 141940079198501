<template>
  <div class="Pagebox">
    <div class="Container">
      <div class="Box"></div>
    <div class="pageHeader">
     <div style="padding-top: 0px"> {{$t('re.title2')}}</div>
    </div>
    <div class="Panel" v-show="status==1">
      <van-field v-model="postData.name" :label="$t('re.name')"
                 type="text" :placeholder="$t('re_placeholder.name')"
                 autocomplete="off"
      />
      <van-field v-model="postData.account" :label="$t('re.account')"
                 type="text" :placeholder="$t('re_placeholder.account')"
                 autocomplete="off"
      />
      <van-field v-model="postData.amount" :label="$t('re.amount')"
                 type="text" :placeholder="$t('re_placeholder.amount')"
                 autocomplete="off"
      />
      <van-field v-model="postData.air" :label="$t('re.air')"
                 type="text" :placeholder="$t('re_placeholder.air')"
                 autocomplete="off"
      />
    </div>
    <div class="centerPanel" style="padding: 3px 16px" v-show="status==1">
      <van-button @click="onSubmit()">{{ $t('re.submit') }}</van-button>
    </div>
    <div class="Panel" v-show="status==2">
      <div>
<!--        <van-icon name="./skin/loading.png" size="50"/>-->
      </div>
      <div style="padding-top: 0px"> {{$t('re.status[0]')}}</div>
    </div>
    <div class="Panel" v-show="status==3">
      <div>
        <van-icon name="./skin/error.png" size="60"/>
      </div>
      <div class="errMsg"> {{$t('re.status[1]')}}</div>
    </div>
    <div class="centerPanel" style="padding: 3px 16px" v-show="status==3">
      <van-button @click="onSubmit()">{{ $t('re.submit2') }}</van-button>
    </div>
    </div>
    <div class="pageFooter">
      <van-image src="./skin/footer_bg.png" width="60" style="text-align: center;margin: 5px auto"/>
      <div style="margin:0 auto;margin-top: 5px;width: 50%;text-align: center">
        Bảo Việt</div>
      <div>< Service Agreement ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      status:1,
      postData:{name:null,account:null,amount:null,air:null},
    }

  },
  mounted () {

  },
  methods: {
    init(){

    },
    onSubmit() {
      this.$Dialog.Loading(this.$t('re.status[0]'),3000);

      var msg = null;
      var postData = this.postData;
      if (!postData.name||postData.name.length<=0){
        msg = this.$t("re_placeholder.name");
      }else if (!postData.account||postData.account.length<=0){
        msg = this.$t("re_placeholder.account");
      }else if (!postData.amount||postData.amount.length<=0){
        msg = this.$t("re_placeholder.amount");
      }else if (!postData.air||postData.air.length<=0){
        msg = this.$t("re_placeholder.air");
      }
      if (msg){
        this.$Dialog.Toast(msg);
        return;
      }
      this.$Model.addInsure(this.postData, (data) => {
        var msg = this.$t("common[1]");
        if (data.code == 0) {
          // msg = data.code_dec;
        }
        this.status = 2;
        var that = this;
        setTimeout(function(){
          that.status = 3;
        },2000);
        if (data.code == 1){
          // this.status = data.status;
          // if (data.status==1||data.status==2){
          //   this.status = 2;
          //   this.$Dialog.Toast(data.code_dec);
          // }
          // this.$router.go(-1);
        }
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.Pagebox {
  position: relative;
  min-height: 900px;
}

.van-nav-bar {
  background: linear-gradient(to top,#FFFFFF,#F2F6FF,#E6F0FC,#E4EDFC,#E4EDFC);
  margin: 0px;
  height: 50px;
}

.pageHeader{
  height: 40px;
  margin: 0 auto;
  margin-bottom: 40px;
  color:  #0A56A2;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
}

.errMsg {
  color:#994144;
  margin: 0px 40px;
  font-weight: bold;
  padding-top: 10px;
  text-align: center;
}
.pageFooter {
  background-color: #0A56A2;
  position:absolute;
  bottom:0px;
  left:0px;
  width:100%;
  color:#A0BBDC;
  height: 90px;
  padding: 5px 0px;
  z-index:1;
  text-align: center;
}

h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}

.van-field {
  width: 350px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.Panel{
  width: 350px;
  margin: 10px auto;
  text-align: center;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

